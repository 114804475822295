
export default (window.addEventListener('load', () => {
    const anchors = document.querySelectorAll("a.back-to-top-anchor");

    anchors.forEach(anchor => {
        anchor.addEventListener("click", () => {
            window.scrollTo(0,0)
        })
    })

}));