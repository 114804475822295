/**
 * ScrollMagic
 * Library Documentation: https://scrollmagic.io/docs/
 * Examples: https://scrollmagic.io/examples/
 * 
 */

( () => {

    let scrollController;

    // Reset animations on Resize
	window.addEventListener("resize", () => {
        if(scrollController) {
            destroyController();
        }
	    initScrollAniumations();
	});

	const destroyController = () => scrollController.destroy(true);

    const initScrollAniumations = () => {

        scrollController = new ScrollMagic.Controller();
    
        if (document.documentElement.clientWidth >= 992) {

            // BOTTOM IMG ANIMATION (BLOCK Heading + image animation)
            const sectionBimg = document.querySelectorAll('.js-sc-b-img');
            sectionBimg.forEach((section) => {   
                const image = section.querySelector('.heading-image-animation__image');
                const heading = section.querySelector('.heading-image-animation__heading');

                // Scenes
                const pinSection = new ScrollMagic.Scene({
                    triggerElement: section,
                    offset: 0,
                    duration: "70%",
                    reverse:true
                }).triggerHook(0)
                .setPin(section)
                .setTween(image, 2, {y: "-100%", ease: Linear.easeInOut})
                // .addIndicators()
                .addTo(scrollController);

                const reduceOpacity = new ScrollMagic.Scene({
                    triggerElement: section,
                    offset: 0,
                    duration: "70%",
                    reverse:true
                }).triggerHook(0)
                .setTween(heading, 1, {opacity: 0.5})
                // .addIndicators()
                .addTo(scrollController);
            });


            // IMG GALLETY HORIZONTAL ANIMATION (BLOCK Section Info + Image Gallery)
            const sectionGallery = document.querySelectorAll('.js-sc-show-gallery');
            sectionGallery.forEach((section) => {   
                const gallery = section.querySelector('.section-info__col--images');
                const numberOfGalleryElements = gallery.childElementCount;
                const text = section.querySelector('.section-info__col--info');
                if (numberOfGalleryElements > 1) {
                    const galleryWidth = gallery.offsetWidth;
                    const sectionHeight = section.offsetHeight;
                    // Scene
                    const pinGallerySection = new ScrollMagic.Scene({
                        triggerElement: section,
                        offset: 0,
                        duration: gallery.offsetWidth,
                        reverse:true
                    }).triggerHook(0)
                    .setPin(section)
                    .setTween(gallery, 2, {x: "-100px", ease: Linear.easeInOut})
                    // .addIndicators()
                    .addTo(scrollController);

                    // Scene
                    const hideText = new ScrollMagic.Scene({
                        triggerElement: section,
                        offset: 300,
                        duration: gallery.offsetWidth,
                        reverse:true
                    }).triggerHook(0)
                    .on("start", function (event) {
                        if (event.scrollDirection === "FORWARD") {
                            text.classList.add('hidden');
                        } else if (event.scrollDirection === "REVERSE") {
                            text.classList.remove('hidden');
                        }
                    })
                    // .addIndicators()
                    .addTo(scrollController);
                }
            });

            // SIDE IMGS ANIMATION (BLOCK Heading + 2 images animation)
            const sectionYimg = document.querySelectorAll('.js-sc-y-imgs');
            sectionYimg.forEach((section) => {    

                const imageLeft = section.querySelector('.heading-2-images-animation__image--left');
                imageRight = section.querySelector('.heading-2-images-animation__image--right');
                heading = section.querySelector('.heading-2-images-animation__heading');
                // Scenes
                pinSection = new ScrollMagic.Scene({
                    triggerElement: section,
                    offset: 0,
                    duration: "70%",
                    reverse:true
                }).triggerHook(0)
                .setPin(section)
                .setTween(imageLeft, 2, {right: "53%", ease: Linear.easeInOut})
                // .addIndicators()
                .addTo(scrollController);

                moveImageRight = new ScrollMagic.Scene({
                    triggerElement: section,
                    offset: 0,
                    duration: "70%",
                    reverse:true
                }).triggerHook(0)
                .setTween(imageRight, 2, {left: "53%", ease: Linear.easeInOut})
                .addTo(scrollController);

                reduceOpacity = new ScrollMagic.Scene({
                    triggerElement: section,
                    offset: 0,
                    duration: "70%",
                    reverse:true
                }).triggerHook(0)
                .setTween(heading, 1, {opacity: 0.5})
                .addTo(scrollController);
            });


            // TEXT & IMGS ANIMATION (BLOCK Text + 3 images animation)
            const sectionSlideIn = document.querySelectorAll('.animation-on-enter');
            sectionSlideIn.forEach((section) => {    
                const text = section.querySelector('.text-3-images-animation__text-wrapper')
                imageLeft = section.querySelector('.text-3-images-animation__image--left')
                imageRight = section.querySelector('.text-3-images-animation__image--right')
                imageCenter = section.querySelector('.text-3-images-animation__image--center')
                // Scenes
                animateText = new ScrollMagic.Scene({
                    triggerElement: section,
                    offset: 0,
                    duration: "150%",
                    reverse: true
                }).triggerHook(0)
                .setPin(section)
                .setTween(text, 1, {top: "15%"})
                .addTo(scrollController);

                animateImageLeft = new ScrollMagic.Scene({
                    triggerElement: section,
                    offset: 0,
                    duration: "150%",
                    reverse: true
                }).triggerHook(0)
                .setTween(imageLeft, 1, {left: "0%"})
                .addTo(scrollController);

                animateImageRight= new ScrollMagic.Scene({
                    triggerElement: section,
                    offset: 0,
                    duration: "150%",
                    reverse: true
                }).triggerHook(0)
                .setTween(imageRight, 1, {right: "0%"})
                .addTo(scrollController);

                animateImageCenter= new ScrollMagic.Scene({
                    triggerElement: section,
                    offset: 0,
                    duration: "150%",
                    reverse: true
                }).triggerHook(0)
                .setTween(imageCenter, 1, {bottom: "5%"})
                .addTo(scrollController);


            });

        }

         // ---------- HEADER BG ------------ //
         const siteHeader = document.querySelector(".site-header");
         
         if (siteHeader) {
            const addHeaderBg = new ScrollMagic.Scene({
                offset: '100px',
                reverse:true
            }).triggerHook(0)
            .setClassToggle(siteHeader, "site-header--bg")
            .addTo(scrollController);
         }

    }

  
} ) ();