import $ from 'jquery';
import 'slick-carousel';


export default $(() => {

    const sliderSingleItem = $( '.slick-slider--single-item' );
    const sliderHover = $( '.slick-slider--hover' );


    // SLIDER SINGLE ITEM
    if ( sliderSingleItem.length ) {
        sliderSingleItem.on('init reInit afterChange', function(event, slick) {
            slick.slideCount === 1 ? $(this).addClass('no-slide') : '' ; 
            const status = $(this).next('.slick-slider__paging-info');
            const currentSlide = $(this).find('.slick-current').data('slick-index') + 1;
            status.text(currentSlide + '/' + (slick.slideCount));
        });

        sliderSingleItem.slick( {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            speed: 200,
            cssEase: 'linear',
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"></button>',
            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"></button>',
        } );
    } 


    // SLIDER HOVER
    if ( sliderHover.length ) {
        sliderHover.slick( {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            speed: 200,
            cssEase: 'none',
        } );

        // Left Colunm
        const sliderLeft = $( '.slick-slider--hover--left' );
        const sliderLeftZoom = $('.slick-slider--hover__zoom-left');
        sliderLeft.on( "mouseover mouseout", function() {
            zoomInImage($(this));
        });
       
        sliderLeftZoom.on("mouseout", function() {
            zoomOutImage($(this));
        });

        // Right Colunm
        const sliderRight = $( '.slick-slider--hover--right' );
        const sliderRightZoom = $('.slick-slider--hover__zoom-right');
        sliderRight.on( "mouseover mouseout", function() {
            zoomInImage($(this));
        });
       
        sliderRightZoom.on("mouseout", function() {
            zoomOutImage($(this));
        });


        const zoomInImage = (imageIn) => {
            if (!imageIn.hasClass('zoom')) {
                imageIn.addClass('zoom');
                imageIn.slick('slickNext');
                const image = imageIn.find('.slick-current img').clone();
                image.removeAttr('class');
                imageIn.next().append(image);
                imageIn.next().show();
            }
        } 
        const zoomOutImage = (imageOut) => {
            imageOut.prev().removeClass('zoom');
            imageOut.prev().slick('slickNext');
            imageOut.find('img').remove();
            imageOut.hide();
        } 

    }


    
    
});


