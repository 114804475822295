/**
 * ScrollMagic
 * Library Documentation: https://scrollmagic.io/docs/
 * Examples: https://scrollmagic.io/examples/
 * 
 */

( () => {

    let pinController;

    // Force Resize on page load to set correct height for animations
    window.addEventListener("load", (e) => {
        window.dispatchEvent(new Event('resize'));
    });

    // Reset animations on Resize
	window.addEventListener("resize", () => {
        if(pinController) {
            destroyController();
        }
	    activatePin();
	});

	const destroyController = () => pinController.destroy(true);

    const activatePin = () => {
    
        if (document.documentElement.clientWidth >= 992) {

            // -------- PIN HEADING ON LEGAL SECTION -------- //
            pinController = new ScrollMagic.Controller();   
            const sectionLegal = document.querySelectorAll('.legal-section');
            sectionLegal.forEach((section) => {     
                const sectionHeight = section.offsetHeight;
                const heading = section.querySelector('.legal-section__col--heading');        

                const pinHeading = new ScrollMagic.Scene({
                    triggerElement: heading,
                    offset: 0,
                    duration: sectionHeight - heading.offsetHeight,
                    reverse:true
                }).triggerHook(0)
                .setPin(heading)
                .addTo(pinController);
            });

        }
    }

  
} ) ();