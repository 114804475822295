window.addEventListener('load', () => {
  activateToggle();
  // setRoomDescriptionHeight();
  // window.addEventListener('resize', setRoomDescriptionHeight());
});



// Toggle Rooms Features
const activateToggle = () => {
  const featuresButtons = document.getElementsByClassName('js-toggle-features');
  for (let i = 0; i < featuresButtons.length; i++) {
    const button = featuresButtons[i];
    const list = button.parentElement.nextElementSibling;
    button.addEventListener('click', () => {
      toggleList(list);
      toggleButtonText(button)
    });
  }
};

const toggleList = (list) => {
  list.style.display = list.style.display === 'none' ? 'block' : 'none';
};

const toggleButtonText = (button) => {
  const showText = button.getElementsByClassName('js-show-features')[0];
  const hideText = button.getElementsByClassName('js-hide-features')[0];
  showText.style.display = showText.style.display === 'none' ? 'block' : 'none';
  hideText.style.display = hideText.style.display === 'none' ? 'block' : 'none';

};

// Check & set min-height for room description box (desktop)
const setRoomDescriptionHeight = () => {
  const roomDescriptionDivs = document.getElementsByClassName('js-room__description');
  if(roomDescriptionDivs.length > 0) {
    for (let i = 1; i < roomDescriptionDivs.length; i += 2) {
      const rowElement1 = roomDescriptionDivs[i - 1];
      const rowElement2 = roomDescriptionDivs[i];
      rowElement1.clientHeight > rowElement2.clientHeight ?
      rowElement2.style.minHeight = `${rowElement1.clientHeight}px` :
      rowElement1.style.minHeight = `${rowElement2.clientHeight}px`;
    }
  }
};
